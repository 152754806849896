import { Controller } from "@hotwired/stimulus"
import { flashNotice } from "../shopify_app/flash_messages"

export default class extends Controller {
  static values = {
    type: { type: String, default: "product" },
    action: { type: String, default: "associate" },
    selectionIds: { type: Array, default: [] }
  }

  static targets = ["attachedVariantId", "attachedProductId", "attachedProductTitle", "attachedProductImageUrl", "attachedPrice", "productCount", "selectedIds"]
 
  async open(event) {
    event.preventDefault();
  
    const selectedIdsString = event.currentTarget.getAttribute('data-resource-picker-selection-ids-value');
    const productOptionGroupId = event.currentTarget.getAttribute('data-resource-picker-product-option-group-id-value');
    const selectedIds = JSON.parse(selectedIdsString || "[]");
    
    console.log('Selected IDs:', selectedIds);
    const options = {
      type: this.typeValue,
      action: this.actionValue,
      multiple: true,
      selectionIds: selectedIds
    };
  
    // Extract the product ID and use it as a query if present
    if (selectedIds.length === 1 && selectedIds[0].id) {
      const productId = selectedIds[0].id.split('/').pop(); // Splits the ID and takes the last segment
      options['query'] = productId;
    }
  
    if (this.typeValue === 'product') {
      options['filter'] = { variants: false };
    } else if (this.typeValue === 'variant') {
      options['type'] = 'product';
      options['filter'] = { variants: true };
      options['multiple'] = false;
    }
    
    console.log('Options:', options);
  
    if (this.typeValue == "variant") {
      this.pick_variant(options);
    } else {
      this.pick_product(options, productOptionGroupId);
    }
  }  

  async pick_product(options, productOptionGroupId) {
    const selected = await shopify.resourcePicker(options);

    // Process the selected products
    const productsData = selected.selection.map(product => {
      return {
        title: product.title,
        id: product.id, // Assuming 'id' is the format "gid://shopify/Product/123456789"
        variant_id: product.variants[0]?.id || '', // Taking the first variant ID, if available
        image_url: product.images[0]?.originalSrc || '' // Taking the first image URL, if available
      };
    });

    if (selected) {
      console.log("Shopify resource picker response:", selected); // Logging the response
      flashNotice('Selected ' + productsData.length + ' ' + this.typeValue + '(s)');

    
        this.associateProductsWithGroup(productsData, productOptionGroupId);
      
    }
  }

  associateProductsWithGroup(productsData, productOptionGroupId) {
    fetch('/associate_products', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({
        product_option_group_id: productOptionGroupId,
        products: productsData
      })
    })
    .then(response => response.json())
    .then(data => {
      console.log('Association successful:', data);
      if (this.hasProductCountTarget) {
        this.productCountTarget.textContent = `${productsData.length} products`;
      }
    })
    .catch(error => console.error('Error:', error));
  }

  async pick_variant(options) {
    const selected = await shopify.resourcePicker(options);

    console.log("Shopify resource picker response:", selected); // Logging the response

    this.attachToFields(selected.selection);
  }

  attachToFields(variantData) {
    if (variantData.length > 0) {
      console.log('Products data:', variantData);
      console.log(variantData[0].title);
  
      // Assuming the first product's ID is what we want to attach
      const productId = variantData[0].id;
      const title = variantData[0].title;
      const imageUrl = variantData[0].images && variantData[0].images.length > 0 ? variantData[0].images[0].originalSrc : null;
      const variantId = variantData[0].variants[0]?.id
      const price = variantData[0].variants[0]?.price * 100
  
      // Correcting the target references
      this.attachedVariantIdTarget.value = variantId;

      if (this.hasAttachedProductTitleTarget) {
        this.attachedProductIdTarget.value = productId;
      } else {
        console.error('attachedProductTitleTarget not found');
      }
  
      // Assuming you have defined targets for these in your Stimulus controller
      if (this.hasAttachedProductTitleTarget) {
        this.attachedProductTitleTarget.value = title;
      } else {
        console.error('attachedProductTitleTarget not found');
      }
  
      if (this.hasAttachedProductImageUrlTarget) {
        this.attachedProductImageUrlTarget.value = imageUrl;
      } else {
        console.error('attachedProductImageUrlTarget not found');
      }

      if (this.hasAttachedPriceTarget) {
        this.attachedPriceTarget.value = price;
      } else {
        console.error('attachedPrice not found');
      }
    }
  }
  
}
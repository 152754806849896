document.addEventListener('turbo:load', function() {
  if (document.querySelector('aside') ) {
 
      if (window.top != window.self)
      {
          let root = document.getElementsByTagName( 'html' )[0]; // '0' to assign the first (and only `HTML` tag)
  
          root.classList.add('framed');
      } else {
          let root = document.getElementsByTagName( 'aside' )[0]; // '0' to assign the first (and only `HTML` tag)
      
          root.classList.remove('hidden'); 
      } 

       // Skip if Shopify app
      if (document.getElementById('shopify-app-init')) { 
              let root = document.getElementsByTagName( 'html' )[0]; // '0' to assign the first (and only `HTML` tag)
              root.classList.remove('framed');
              
              let aside = document.getElementsByTagName( 'aside' )[0]; // '0' to assign the first (and only `HTML` tag)
              aside.classList.remove('hidden'); 
      }

  } else {
      //return;
  }
 
});
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "container" ]

  // connect() {
  //   console.log('connected')
  // }

  hide() {
    this.containerTarget.classList.add("hidden")
  }
}

//https://github.com/adrienpoly/stimulus-flatpickr
import Flatpickr from 'stimulus-flatpickr'

// you can also import a translation file
//import { French } from 'flatpickr/dist/l10n/fr.js'

// import a theme (could be in your main CSS entry too...)
//import 'flatpickr/dist/themes/dark.css'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {

  connect() {
    //always call super.connect()
    super.connect();
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  // change(selectedDates, dateStr, instance) {
  //   console.log('the callback returns the selected dates', selectedDates)
  //   console.log('but returns it also as a string', dateStr)
  //   console.log('and the flatpickr instance', instance)
  // }

  clear(instance) {
      this.fp.clear()
  }
}

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "output", "input", "hidden", "clear" ]

  readURL() {
    var input = this.inputTarget
    var output = this.outputTarget
    

    if (input.files && input.files[0]) {

      if(input.files[0].size > 10097152){
        alert("File is too big! The file must be under 10MB");
        return;
     };

     if ( /\.(jpe?g|png|gif)$/i.test(input.files[0].name) === false ) { 
       alert("File extension not accepted. Upload JPG, PNG, GIF format files only."); 
       return;
      }
      

    if (input.files && input.files[0].type === 'image/jpeg') {
      // do nothing
    } else if (input.files && input.files[0].type === 'image/png') {
     // do nothing
    } else if (input.files && input.files[0].type === 'image/gif') {
      // do nothing
    } else {
       alert( 'File format not accepted. Upload JPG, PNG, GIF format images only. ');
       return;
    }
    

      var reader = new FileReader();

      reader.onload = function () {
       output.src = reader.result
     }

     reader.readAsDataURL(input.files[0]);
     this.clearTarget.classList.remove("hidden");

     this.hiddenTarget.value = '0'

     // Hide / Show Offer preview image 
     document.getElementsByClassName("offer__image")[0].classList.remove("hidden");
 
   }
 }

 clear() {
  this.inputTarget.value = ''
  this.hiddenTarget.value = '1'
  this.outputTarget.src = this.outputTarget.getAttribute("data-placeholder")
  this.clearTarget.classList.add("hidden");

  // Hide / Show Offer preview image 
  document.getElementsByClassName("offer__image")[0].classList.add("hidden");
 
 }

}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["fileInput", "deleteImage", "icon", "previewImage", "removeButton"];

  connect() {
    //this.updateVisibility();
    this.fileInputTarget.addEventListener('change', this.fileSelected.bind(this));

  }

  triggerFileSelect() {
    this.fileInputTarget.click();
  }

  fileSelected(event) {
    const files = event.target.files;
    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.previewImageTarget.src = e.target.result;
        this.previewImageTarget.classList.remove("hidden");
        this.removeButtonTarget.classList.remove("hidden");
        this.iconTarget.classList.add("hidden");
      };
      reader.readAsDataURL(file);
    }
  }

  removeAttachedImage() {
    this.previewImageTarget.src = '';
    this.previewImageTarget.classList.add("hidden");
    this.removeButtonTarget.classList.add("hidden");
    this.iconTarget.classList.remove("hidden");
    this.fileInputTarget.value = ""; // Clear the file input
    this.deleteImageTarget.value = 1; // Set the delete flag
  }

  updateVisibility() {
    if (this.fileInputTarget.files.length > 0 || this.previewImageTarget.src !== '') {
      this.iconTarget.classList.add("hidden");
      this.previewImageTarget.classList.remove("hidden");
      this.removeButtonTarget.classList.remove("hidden");
    } else {
      this.iconTarget.classList.remove("hidden");
      this.previewImageTarget.classList.add("hidden");
      this.removeButtonTarget.classList.add("hidden");
    }
  }
}

// app/javascript/controllers/dismiss_card_controller.js

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["card"];
  static values = { key: String };

  connect() {
    this.updateCardVisibility();
  }

  updateCardVisibility() {
    const key = this.keyValue || "defaultCardKey";
    const isDismissed = localStorage.getItem(key) === "true";

    if (isDismissed) {
      this.cardTarget.classList.add("hidden");
    } else {
      this.cardTarget.classList.remove("hidden");
    }
  }

  toggleCard() {
    const key = this.keyValue || "defaultCardKey";
    const isDismissed = localStorage.getItem(key) === "true";
    localStorage.setItem(key, !isDismissed);

    this.updateCardVisibility();
  }
}

function isPWA() {
    return window.matchMedia('(display-mode: standalone)').matches ||
           window.matchMedia('(display-mode: fullscreen)').matches ||
           window.matchMedia('(display-mode: minimal-ui)').matches ||
           window.navigator.standalone === true;
  }
  
  document.addEventListener('turbo:load', () => {
    if (isPWA()) {
      document.body.classList.add('pwa-mode');
    }
  });  
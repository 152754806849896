// app/javascript/controllers/pwa_install_controller.js
import { Controller } from "@hotwired/stimulus";
import '@khmyznikov/pwa-install';

export default class extends Controller {
  static targets = ["eventsArea"];

  connect() {
    this.pwaInstall = document.getElementById("pwa-install");

    if (this.pwaInstall) {
        this.pwaInstall.addEventListener('pwa-install-success-event', this.logMessage.bind(this));
        this.pwaInstall.addEventListener('pwa-install-fail-event', this.logMessage.bind(this));
        this.pwaInstall.addEventListener('pwa-user-choice-result-event', this.logMessage.bind(this));
        this.pwaInstall.addEventListener('pwa-install-available-event', this.logMessage.bind(this));
        this.pwaInstall.addEventListener('pwa-install-how-to-event', this.logMessage.bind(this));
        this.pwaInstall.addEventListener('pwa-install-gallery-event', this.logMessage.bind(this));
    } else {
    // console.error("PWA Install element not found");
    }
 }

  logMessage(event) {
    const message = event.detail.message;
    // console.log(message);
    // this.eventsAreaTarget.value += `>: ${message}\r\n`;
  }

  getRelated() {
    this.pwaInstall.getInstalledRelatedApps().then((result) => {
      this.logMessage({ detail: { message: result.toString() || 'None' } });
    });
  }

  forceStyle(event) {
    const style = event.target.value;
    switch (style) {
      case 'apple-mobile':
        this.pwaInstall.isAppleDesktopPlatform = false;
        this.pwaInstall.isAppleMobilePlatform = true;
        break;
      case 'apple-desktop':
        this.pwaInstall.isAppleMobilePlatform = false;
        this.pwaInstall.isAppleDesktopPlatform = true;
        break;
      case 'chrome':
        this.pwaInstall.isAppleMobilePlatform = false;
        this.pwaInstall.isAppleDesktopPlatform = false;
        break;
    }
    this.pwaInstall.hideDialog();
    this.pwaInstall.showDialog();
  }

  disableInstallDescription(event) {
    event.target.checked ? this.pwaInstall.setAttribute('disable-install-description', true) : this.pwaInstall.removeAttribute('disable-install-description');
  }

  disableScreenshots(event) {
    event.target.checked ? this.pwaInstall.setAttribute('disable-screenshots', true) : this.pwaInstall.removeAttribute('disable-screenshots');
  }

  disableClose(event) {
    event.target.checked ? this.pwaInstall.setAttribute('disable-close', true) : this.pwaInstall.removeAttribute('disable-close');
  }

  setAttr(attr, value) {
    value ? this.pwaInstall.setAttribute(attr, value) : this.pwaInstall.removeAttribute(attr);
    this.pwaInstall._init();
  }

  download() {
    this.pwaInstall.showDialog(true);
  }
}
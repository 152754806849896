import { Controller } from "@hotwired/stimulus"
import * as basicLightbox from 'basiclightbox'

export default class extends Controller {
  connect() {  
  }

  open() {
    let src = this.data.get("src")
    // console.log(src)
    const instance = basicLightbox.create(`
      <iframe src="${src}" width="100%" height="500" frameborder="0"></iframe>
    `)
  
    instance.show()
  }
}
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  navigate(event) {
    event.preventDefault()

    const url = new URL(event.currentTarget.href)

    if (shopify) {
      url.searchParams.set('shop', shopify.config.shop)
      url.searchParams.set('host', shopify.config.host)
    }

    // Get locale from the <html> tag's 'lang' attribute
    const locale = document.documentElement.lang || 'en' // Default to 'en' if not set
    url.searchParams.set('locale', locale)

    // console.log('[shopify_app] Redirecting to:', url.toString())
    history.pushState(null, '', url.toString())
    Turbo.visit(url)
  }
}